import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

const AttendanceChartByMonth = ({ selectedBranch }) => {
  const [attendanceData, setAttendanceData] = useState(null);

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        console.log("Selected Branch:", selectedBranch); // Log the value of selectedBranch
        // Fetch attendance data for the selected branch from the API
        const response = await axios.get(`https://superadmin.marsbpo.org/api/${selectedBranch}/attendance-summary-month`);
        setAttendanceData(response.data);
      } catch (error) {
        console.error('Error fetching attendance data:', error);
      }
    };
  
    fetchAttendanceData();
  }, [selectedBranch]);

  if (!attendanceData) {
    return <div className='text-black'>No data found for last month !</div>;
  }

  // Convert string values to numbers
  const { presentCount, absentCount, leaveCount, lateCount, WFHCount } = attendanceData;
  const parsedAttendanceData = {
    presentCount: parseInt(presentCount),
    absentCount: parseInt(absentCount),
    leaveCount: parseInt(leaveCount),
    lateCount: parseInt(lateCount),
    WFHCount: parseInt(WFHCount),
  };

  const chartOptions = {
    labels: ['Present', 'Absent', 'Leave', 'Late','WFH'],
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontSize: '14px',
      markers: {
        width: 12,
        height: 12,
        radius: 12,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.globals.labels[opts.seriesIndex] + ": " + val.toFixed(1) + "%";
      }
    }
  };

  const chartSeries = [
    parsedAttendanceData.presentCount,
    parsedAttendanceData.absentCount,
    parsedAttendanceData.leaveCount,
    parsedAttendanceData.lateCount,
    parsedAttendanceData.WFHCount
  ];

  return (
    <div style={{ backgroundColor: "#F4F4F4"}} className=" shadow-lg rounded-lg p-4 text-center">
      <h2 className="text-2xl font-semibold mb-4">Attendance Summary for Last Month</h2>
      <ReactApexChart options={chartOptions} series={chartSeries} type="donut" height={350} />
    </div>
  );
};

export default AttendanceChartByMonth;
