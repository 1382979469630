import React, { useState, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import "./Login.css"; 

// Lazy load Animation component
const Animation = React.lazy(() => import("./Animation"));

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setLoading(true);

      if (!email || !password) {
        setError("Username and password are required");
        setLoading(false);
        return;
      }

      const response = await fetch("https://superadmin.marsbpo.org/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const responseData = await response.json();
        setError(responseData.error || "Login failed");
        throw new Error("Login failed");
      }

      const data = await response.json();
      localStorage.setItem("token", data.token);

      navigate("/dashboard");
      console.log("Login successful:", data);
    } catch (error) {
      console.error("Error during login:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div id="login" className="min-h-screen flex justify-center items-center">
      <Suspense fallback={<div>Loading Animation...</div>}>
        <Animation />
      </Suspense>
      <div className="w-full max-w-md px-4">
        <div className="bg-white dark:bg-slate-900 shadow-lg sm:rounded-lg sm:p-8 border border-white-200 dark:border-white-700">
          <img className="h-30 w-full mb-8" src="https://marsbpo.org/images/marsbpo.png" alt="logo" />
          <h1 className="text-2xl font-semibold text-center text-gray-800 dark:text-gray-300 mb-8">
            Welcome to Mars
          </h1>
          <div className="space-y-4">
            <div className="relative">
              <input
                autoComplete="off"
                id="email"
                name="email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="peer placeholder-transparent h-12 w-full rounded-lg bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-800 dark:text-gray-300 focus:outline-none focus:border-rose-600 px-4"
                placeholder="Email"
              />
              <label
                htmlFor="email"
                className="absolute left-4 -top-3.5 text-gray-600 dark:text-gray-400 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-1 transition-all peer-focus:-top-5.5 peer-focus:text-white-600 peer-focus:text-xs px-1"
              >
                Email
              </label>
            </div>
            <div className="relative">
              <input
                autoComplete="off"
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="peer placeholder-transparent h-12 w-full rounded-lg bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-800 dark:text-gray-300 focus:outline-none focus:border-rose-600 px-4"
                placeholder="Password"
              />
              <label
                htmlFor="password"
                className="absolute left-4 -top-3.5 text-gray-600 dark:text-gray-400 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-1 transition-all peer-focus:-top-5.5 peer-focus:text-white-600 peer-focus:text-xs px-1"
              >
                Password
              </label>
              <div className="absolute right-4 top-3 cursor-pointer" onClick={togglePasswordVisibility}>
                {showPassword ? <RiEyeOffLine className="text-gray-600 dark:text-gray-400" /> : <RiEyeLine className="text-gray-600 dark:text-gray-400" />}
              </div>
            </div>
            {error && <div className="text-red-500 text-sm">{error}</div>}
            <div className="relative">
              <button
                className="bg-blue-500 text-white rounded-md px-4 py-2 w-full hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? "Loading..." : "Login"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
