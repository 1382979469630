import React, { useState, useEffect } from 'react';
import { FaArrowRight, FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Branch1Graph from './graphs/Branch1Graph';
import Branch2Graph from './graphs/Branch2Graph';

const Branches = () => {
  const [branch1Data, setBranch1Data] = useState(0);
  const [branch2Data, setBranch2Data] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBranchData = async (branchEndpoint, setBranchData) => {
      try {
        const response = await fetch(branchEndpoint);
        if (!response.ok) {
          throw new Error(`Failed to fetch branch data from ${branchEndpoint}`);
        }
        const data = await response.json();
        setBranchData(data);
      } catch (error) {
        console.error(`Error fetching branch data from ${branchEndpoint}:`, error.message);
      }
    };

    fetchBranchData('https://superadmin.marsbpo.org/api/branch1/data', setBranch1Data);
    fetchBranchData('https://superadmin.marsbpo.org/api/branch2/data', setBranch2Data);
  }, []);

  const BranchCard = ({ branchName, employees, onBranchClick, title, icon }) => (
    <div className="max-w-xl mx-auto mb-8 rounded-lg overflow-hidden shadow-lg bg-white dark:bg-gray-800">
      <div className="px-6 py-4 bg-gray-200  text-black flex items-center justify-between text-center">
        <h3 className="text-2xl w-full font-semibold">{title}</h3>
      </div>

      <div style={{ backgroundColor: '#F4F4F4'}}  className="p-6 ">
        {branchName === 'branch1' && <Branch1Graph />}
        {branchName === 'branch2' && <Branch2Graph />}
        
        <div className="flex justify-between items-center mt-6">
          <div>
            <p className="text-black font-semibold ">Total Employees: {employees}</p>
          </div>
          <button
            className="inline-flex items-center gap-x-1 text-md bg-white font-semibold rounded-lg shadow-lg px-2 py-2 border border-transparent text-black hover:text-blue-800   dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            onClick={() => onBranchClick(branchName)}
          >
            See Details
            <svg
              className="flex-shrink-0 w-4 h-4 ml-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8.293 13.707a1 1 0 0 0 1.414 0L12 10.414V15a1 1 0 0 0 2 0V9a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 2 0v-4.586l2.293 2.293a1 1 0 0 0 1.414-1.414L8.293 13.707z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );

  const handleBranchClick = (branchName) => {
    navigate(`/home?branch=${encodeURIComponent(branchName)}`);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {branch1Data !== null && (
        <BranchCard
          branchName="branch1"
          title="Mars Digital"
          employees={branch1Data}
          onBranchClick={handleBranchClick}
          icon={<FaArrowRight size={24} />}
        />
      )}

      {branch2Data !== null && (
        <BranchCard
          branchName="branch2"
          title="Mars Capital"
          employees={branch2Data}
          onBranchClick={handleBranchClick}
          icon={<FaArrowRight size={24} />}
        />
      )}
    </div>
  );
};

export default Branches;
