import React from 'react';
import Branches from './Branches';
import dashboard from "./assets/dashboard.webp"

const Dashboard = () => {
  return (
    <div
      className="min-h-screen px-4 py-8 relative"
      style={{
        backgroundImage: `url(${dashboard})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Blurred background */}
      <div
        className="absolute inset-0 bg-white opacity-50 blur-lg rounded-md"
        style={{ backdropFilter: 'blur(40px)', zIndex: -1 }}
      />

      {/* Content */}
      <div className="container mx-auto relative z-10">
        <h1 className="text-3xl font-semibold mb-8 mt-4 text-center text-white">YOUR GATEWAY TO CONTROL!</h1>
        <div className="flex justify-evenly">
          <Branches />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
