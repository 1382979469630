import React, { useState } from 'react';
import { FaCalendarAlt, FaDownload } from 'react-icons/fa';
import axios from 'axios';
import * as XLSX from "xlsx";
import ReactPaginate from 'react-paginate';

const SearchAttendance = ({ selectedbranch }) => {
  const [isSingleDate, setIsSingleDate] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [attendanceData, setAttendanceData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 20;

  const handleToggle = () => {
    setIsSingleDate(!isSingleDate);
  };

  const handleSearch = async () => {
    try {
      let url = `https://superadmin.marsbpo.org/api/${selectedbranch}/attendance`;
      if (isSingleDate) {
        url += `/date/${startDate}`;
      } else {
        url += `/multiple/${startDate}/${endDate}`;
      }

      const response = await axios.get(url);
      setAttendanceData(response.data);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const exportToExcel = () => {
    const fileName = 'attendance_data.xlsx';
    const workbook = XLSX.utils.book_new();

    for (const status in attendanceData) {
      const data = attendanceData[status];
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, status);
    }

    XLSX.writeFile(workbook, fileName);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const offset = currentPage * itemsPerPage;

  return (
    <div > {/* Center align the entire component */}
      <div style={{ backgroundColor: "#F4F4F4", width: "100%" }} className="rounded-lg shadow-lg p-6 md:p-8 my-10 space-y-4">
        <h2 className="text-lg font-semibold mb-4">Search Attendance</h2>
        <div className="flex flex-wrap items-center space-x-4">
          <div className="flex items-center space-x-2">
            <FaCalendarAlt className="text-gray-600 " />
            <label htmlFor="date" className="text-gray-800 ">
              {isSingleDate ? 'Select Date:' : 'Select Date Range:'}
            </label>
            {isSingleDate ? (
              <input
                type="date"
                id="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
              />
            ) : (
              {/* <>
                <input
                  type="date"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="flex-1 p-2 border border-gray-300 rounded"
                />
                <span className="text-gray-600">to</span>
                <input
                  type="date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="flex-1 p-2 border border-gray-300 rounded"
                />
              </> */}
            )}
          </div>
          <div>
            <button
              onClick={handleSearch}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Search
            </button>
          </div>
          {Object.keys(attendanceData).length > 0 && ( // Render export button only when data is fetched
            <div className="ml-auto flex items-center"> {/* Move button to right using flexbox */}
              <button
                onClick={exportToExcel}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 flex items-center"
              >
                Export to Excel <FaDownload className="ml-2" /> {/* Move icon to the right */}
              </button>
            </div>
          )}
        </div>

        {/* Displaying data separately by status */}
        {Object.keys(attendanceData).map(status => (
          <div key={status}>
            <h3 className="text-lg font-semibold mt-6 mb-4">{status.toUpperCase()}</h3>
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2 border border-gray-300 text-left">Employee Name</th>
                  <th className="px-4 py-2 border border-gray-300 text-left">Department</th>
                  <th className="px-4 py-2 border border-gray-300 text-left">Status</th>
                  <th className="px-4 py-2 border border-gray-300 text-left">Start Time</th>
                  <th className="px-4 py-2 border border-gray-300 text-left">Date</th>
                </tr>
              </thead>
              <tbody>
                {attendanceData[status].slice(offset, offset + itemsPerPage).map((attendance, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-4 py-2 border border-gray-300 text-left">{attendance.name}</td>
                    <td className="px-4 py-2 border border-gray-300 text-left">{attendance.department}</td>
                    <td className="px-4 py-2 border border-gray-300 text-left">{attendance.status}</td>
                    <td className="px-4 py-2 border border-gray-300 text-left">{attendance.startTime || '-'}</td>
                    <td className="px-4 py-2 border border-gray-300 text-left">{formatDate(attendance.date) || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}

        {/* Pagination */}
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          pageCount={Math.max(
            ...Object.values(attendanceData).map(data => Math.ceil(data.length / itemsPerPage))
          )}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </div>
    </div>
  );
};

export default SearchAttendance;
