import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';

const dateOptions = [
  { value: 'lastWeek', label: 'Last Week' },
  { value: 'lastMonth', label: 'Last Month' },
  { value: 'last3Months', label: 'Last 3 Months' },
  { value: 'lastYear', label: 'Last Year' },
  { value: 'custom', label: 'Custom' },
];

const getDateRange = (option) => {
  let endDate = new Date();
  let startDate = new Date();
  switch (option) {
    case 'lastWeek':
      startDate.setDate(endDate.getDate() - 7);
      break;
    case 'lastMonth':
      startDate.setMonth(endDate.getMonth() - 1);
      break;
    case 'last3Months':
      startDate.setMonth(endDate.getMonth() - 3);
      break;
    case 'lastYear':
      startDate.setFullYear(endDate.getFullYear() - 1);
      break;
    default:
      startDate = null;
      endDate = null;
  }
  return { startDate, endDate };
};

const AttendanceChartByDateRange = ({ selectedBranch }) => {
  const [attendanceData, setAttendanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRangeOption, setDateRangeOption] = useState(dateOptions[0]);
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());

  useEffect(() => {
    const fetchAttendanceData = async (startDate, endDate) => {
      try {
        const response = await axios.get(`https://superadmin.marsbpo.org/api/${selectedBranch}/attendance-summary-date-range`, {
          params: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        });

        const data = response.data;

        setAttendanceData({
          options: {
            chart: {
              type: 'line',
              height: 350,
              toolbar: {
                show: true
              },
            },
            plotOptions: {
              bar: {
                distributed: true,
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
              },
            },
            xaxis: {
              categories: ['Leave', 'Present', 'Late', 'Absent','WFH'],
            },
          },
          series: [{
            name: 'Count',
            data: [data.leaveCount, data.presentCount, data.lateCount, data.absentCount,data.WFHCount]
          }]
        });

        setLoading(false);
      } catch (error) {
        console.error('Error fetching attendance data:', error);
        setError('Error fetching attendance data. Please try again.');
        setLoading(false);
      }
    };

    const { startDate, endDate } = getDateRange(dateRangeOption.value);
    if (dateRangeOption.value !== 'custom') {
      fetchAttendanceData(startDate, endDate);
    } else if (customStartDate && customEndDate) {
      fetchAttendanceData(customStartDate, customEndDate);
    }
  }, [selectedBranch, dateRangeOption, customStartDate, customEndDate]);

  return (
    <div style={{ backgroundColor: '#F4F4F4' }} className='shadow-lg rounded-lg p-4 text-center'>
      <h2 className='text-2xl font-semibold mb-4'>Attendance Analytics</h2>
      <div style={{ width: 200 }}>
        <Select
          options={dateOptions}
          value={dateRangeOption}
          onChange={setDateRangeOption}
          className='mb-4'
        />
      </div>
      {dateRangeOption.value === 'custom' && (
        <div className="flex justify-center mb-4">
          <div>
            <label className='font-semibold mr-1'>Start Date:</label>
            <DatePicker selected={customStartDate} onChange={date => setCustomStartDate(date)} className="text-center mr-3 " />
          </div>
          <label className='font-semibold mr-1'>End Date:</label>
          <DatePicker selected={customEndDate} onChange={date => setCustomEndDate(date)} className="text-center" />
        </div>
      )}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ReactApexChart options={attendanceData.options} series={attendanceData.series} type='line' height={350} />
      )}
    </div>
  );
};

export default AttendanceChartByDateRange;
