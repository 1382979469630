// MainCards.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaUserTimes, FaUserCheck, FaBed, FaClock, FaUsers } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

const Card = ({ icon, bgColor, title, subtitle, onClick }) => {
  return (
    <div style={{ backgroundColor: '#F4F4F4', transition: 'opacity 0.3s ease' }} className={`flex flex-col items-center justify-center p-6 shadow-lg rounded-lg border-black hover:shadow-xl transition duration-300 ease-in-out`} onClick={onClick}>
    <div className={`flex items-center justify-center h-16 w-16 rounded-full mb-4 shadow-md border border-gray-300`} style={{ backgroundColor: bgColor }}>
      {icon}
    </div>
    <div className="text-center">
      <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
      <p className="text-gray-700">{subtitle}</p>
    </div>
  </div>
  );
};

const MainCards = ({ selectedbranch, date }) => {
  const [presentCount, setPresentCount] = useState("");
  const [absentCount, setAbsentCount] = useState("");
  const [WFHCount, setWFHCount] = useState("");
  const [leaveCount, setLeaveCount] = useState("");
  const [lateCount, setLateCount] = useState("");
  const [totalEmployees, setTotalEmployees] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const attendanceSummaryResponse = await axios.get(`https://superadmin.marsbpo.org/api/${selectedbranch}/attendance-summary/${date}`);
        const attendanceSummaryData = attendanceSummaryResponse.data;
        setPresentCount(attendanceSummaryData.presentCount);
        setAbsentCount(attendanceSummaryData.absentCount);
        setWFHCount(attendanceSummaryData.WFHCount);
        setLeaveCount(attendanceSummaryData.leaveCount);
        setLateCount(attendanceSummaryData.lateCount);

        const employeesResponse = await axios.get(`https://superadmin.marsbpo.org/api/${selectedbranch}/employees`);
        const employeesData = employeesResponse.data;
        setTotalEmployees(employeesData.length);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again.");
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedbranch, date]);

  const handleAbsentCardClick = async () => {
    try {
      const response = await axios.get(`https://superadmin.marsbpo.org/api/${selectedbranch}/get-absent-employees/${date}`);
      const absentEmployees = response.data;
      navigate('/absent-employees-details', { state: { absentEmployees } });
    } catch (error) {
      console.error("Error fetching absent employees:", error);
    }
  };

  const handleWFHCardClick = async () => {
    try {
      const response = await axios.get(`https://superadmin.marsbpo.org/api/${selectedbranch}/get-WFH-employees/${date}`);
      const WFHEmployees = response.data;
      navigate('/WFH-employees-details', { state: { WFHEmployees } });
    } catch (error) {
      console.error("Error fetching WFH employees:", error);
    }
  };
  
  const handlePresentCardClick = async () => {
    try {
      const response = await axios.get(`https://superadmin.marsbpo.org/api/${selectedbranch}/get-present-employees/${date}`);
      const presentEmployees = response.data;
      navigate('/present-employees-details', { state: { presentEmployees } });
    } catch (error) {
      console.error("Error fetching present employees:", error);
    }
  };

  const handleLeaveCardClick = async () => {
    try {
      const response = await axios.get(`https://superadmin.marsbpo.org/api/${selectedbranch}/get-leave-employees/${date}`);
      const leaveEmployees = response.data;
      navigate('/leave-employees-details', { state: { leaveEmployees } });
    } catch (error) {
      console.error("Error fetching leave employees:", error);
    }
  };
  
  const handleLateCardClick = async () => {
    try {
      const response = await axios.get(`https://superadmin.marsbpo.org/api/${selectedbranch}/get-late-employees/${date}`);
      const lateEmployees = response.data;
      navigate('/late-employees-details', { state: { lateEmployees } });
    } catch (error) {
      console.error("Error fetching late employees:", error);
    }
  };

  const handleAllEmployee = async () => {
    try {
      const response = await axios.get(`https://superadmin.marsbpo.org/api/${selectedbranch}/employees`);
      const employees = response.data;

      // Organize employees by department
      const employeesByDepartment = {};
      employees.forEach(employee => {
        const department = employee.department;
        if (!employeesByDepartment[department]) {
          employeesByDepartment[department] = [];
        }
        employeesByDepartment[department].push(employee);
      });
  
      navigate('/all-employees-details', { state: employeesByDepartment });
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  }

  return (
    <div>
      {loading ? "Loading..." : error}
      <section className="grid md:grid-cols-2 xl:grid-cols-6 gap-6">
        <Card
          icon={<FaUserTimes color="#fff" size={32} />}
          bgColor="#F87171"
          title={absentCount}
          subtitle="Absent Employees"
          onClick={handleAbsentCardClick}
        />
        <Card
          icon={<FaUserCheck color="#fff" size={32} />}
          bgColor="#4ADE80"
          title={presentCount}
          subtitle="Present Employees"
          onClick={handlePresentCardClick}
        />
        <Card
          icon={<FaBed color="#fff" size={32} />}
          bgColor="#60A5FA"
          title={leaveCount}
          subtitle="Leave Count"
          onClick={handleLeaveCardClick}
        />
        <Card
          icon={<FaClock color="#fff" size={32} />}
          bgColor="#F59E0B"
          title={lateCount}
          subtitle="Late Employees"
          onClick={handleLateCardClick}
        />
         <Card
          icon={<FaClock color="#fff" size={32} />}
          bgColor="#9F7AEA"
          title={WFHCount}
          subtitle="WFH Employees"
          onClick={handleWFHCardClick}
        />
        <Card
          icon={<FaUsers color="#fff" size={32} />}
          bgColor="purple"
          title={totalEmployees}
          subtitle="All Employees"
          onClick={handleAllEmployee}
        />
      </section>
    </div>
  );
};

export default MainCards;
