import axios from "axios";
import ReactApexChart from 'react-apexcharts';
import { useState, useEffect } from "react";

const AttendanceSummary = ({ selectedBranch, date }) => {
  const [attendanceData, setAttendanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://superadmin.marsbpo.org/api/${selectedBranch}/attendance-summary/${date}`);
        const data = response.data;

        setAttendanceData({
          options: {
            chart: {
              type: 'bar',
              height: 350,
              toolbar: {
                show: true
              },
            },
            plotOptions: {
              bar: {
                distributed: true,
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
              },
            },
            xaxis: {
              categories: ['Leave', 'Present', 'Late', 'Absent','WFH'],
            },
          },
          series: [{
            name: 'Count',
            data: [data.leaveCount, data.presentCount, data.lateCount, data.absentCount,data.WFHCount]
          }]
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again.");
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedBranch, date]);

  return (
    <div style={{ backgroundColor: "#F4F4F4" }} className=" shadow-lg rounded-lg p-4 text-center">
      <h2 className="text-2xl font-semibold mb-4">Attendance Summary for {date}</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ReactApexChart options={attendanceData.options} series={attendanceData.series} type="bar" height={350} />
      )}
    </div>
  );
};

export default AttendanceSummary;
