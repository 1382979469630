import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import EmployeesPerDepartmentChart from "./EmployeesPerDepartmentChart";
import AttendanceChartByWeek from "./AttendanceChartByWeek";
import AttendanceSummary from "./AttendanceSummary";
import MainCards from "./MainCards";
import AttendanceChartByMonth from "./AttendanceChartByMonth";
import AttendanceChartByDateRange from "./AttendanceChartByDateRange"; // Import new component
import { FaArrowLeft, FaUser, FaSignOutAlt } from "react-icons/fa";
import SearchAttendance from "./SearchAttendance";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const twoDaysAgo = new Date();
twoDaysAgo.setDate(twoDaysAgo.getDate() - 2); // Subtract 2 days from today

const Home = () => {
  const [currentDate, setCurrentDate] = useState(() => {
    const storedDate = localStorage.getItem("selectedDate");
    return storedDate ? new Date(storedDate) : new Date();
  });
  const [startDate, setStartDate] = useState(twoDaysAgo); // Initialize with two days ago
  const [endDate, setEndDate] = useState(new Date()); // Initialize with today's date
  const [searchClicked, setSearchClicked] = useState(false); // State to track if the search button is clicked
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const branch = params.get("branch");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleChangeDate = (e) => {
    const newDate = new Date(e.target.value);
    setCurrentDate(newDate);
    localStorage.setItem("selectedDate", formatDate(newDate));
  };

  const handleSwitchToToday = () => {
    localStorage.removeItem("selectedDate");
    setCurrentDate(new Date());
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  const handleBack = () => {
    window.history.back();
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div>
        <header style={{ backgroundColor: '#F4F4F4' }} className="flex items-center h-20 px-6 sm:px-10">
        <button
          id="login"
          onClick={handleBack}
          className="flex items-center rounded text-white py-2 px-2  bg-blue-900 hover:bg-blue-600"
        >
          <FaArrowLeft className="mr-2" />
          Go Back
        </button>
         
          <div className="flex flex-shrink-0 items-center ml-auto">
            <button className="inline-flex items-center p-2 hover:bg-gray-100 focus:bg-gray-100 rounded-lg">
              <span className="sr-only">User Menu</span>
              <div className="hidden md:flex md:flex-col md:items-end md:leading-tight">
                <span className="font-semibold">Mehar Shoaib</span>
                <span className="text-sm text-gray-600">Founder Mars</span>
              </div>
              <span className="h-12 w-12 ml-2 sm:ml-3 mr-2 bg-gray-100 rounded-full overflow-hidden">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4EEzBK8Q-VcY6-HXLdnMSMCCPAeaZLcGBuMwEK3h5hzpIb7DFcFQ-cGVREKB85cGiuZ4&usqp=CAU" alt="user profile " className="h-full w-full object-cover" />
              </span>

            </button>
            <div className="border-l pl-3 ml-3 space-x-1">
              <button className="relative p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:bg-gray-100 focus:text-gray-600 rounded-full">
                <span className="sr-only">Notifications</span>
                <span className="absolute top-0 right-0 h-2 w-2 mt-1 mr-2 bg-red-500 rounded-full"></span>
                <span className="absolute top-0 right-0 h-2 w-2 mt-1 mr-2 bg-red-500 rounded-full animate-ping"></span>
                <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
              </button>
              <button onClick={handleLogout} className="relative p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:bg-gray-100 focus:text-gray-600 rounded-full">
                <span className="sr-only">Log out</span>
                <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
              </button>
            </div>
          </div>
        </header>
      </div>

      <main className="p-6 bg-white sm:p-10 space-y-6">
        <div className="flex flex-col space-y-6 md:space-y-0  md:flex-row justify-between">
          <div className="flex justify-between items-center mr-6">
          <div>
            <h1 className="text-4xl end-0 font-semibold mb-2">Super Admin</h1>
          </div>
            <input
              type="date"
              value={formatDate(currentDate)}
              onChange={handleChangeDate}
              className="text-2xl absolute mr-10 end-0 font-semibold mb-2"
            />
            <button onClick={handleSwitchToToday} className="text-sm font-semibold absolute mr-10 end-0 mt-12 text-blue-950">Switch to Today</button>
          </div>
        </div>
        <MainCards selectedbranch={branch} date={formatDate(currentDate)} />

        <div className="row-span-5 py-6 shadow rounded-lg">
          <AttendanceSummary selectedBranch={branch} date={formatDate(currentDate)} />
        </div>
        <section className="grid md:grid-cols-2 xl:grid-cols-3 xl:grid-col-3 xl:grid-flow-col gap-6">
          <EmployeesPerDepartmentChart selectedbranch={branch} date={formatDate(currentDate)} />
          <AttendanceChartByWeek selectedbranch={branch} date={formatDate(currentDate)} />
          <AttendanceChartByMonth selectedBranch={branch} />
        </section>
              <div>
                <AttendanceChartByDateRange
                  selectedBranch={branch}
                />
              </div>
        <SearchAttendance selectedbranch={branch} />
      </main>
    </div>
  );
};

export default Home;
