import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';

const EmployeesPerDepartmentChart = ({ selectedbranch }) => {
  const [departmentData, setDepartmentData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://superadmin.marsbpo.org/api/${selectedbranch}/max-employees-in-department`);
        // Extract department data from the response
        const departments = Object.values(response.data.maxEmployeesInDepartment);
        setDepartmentData(departments);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedbranch]);

  const chartOptions = {
    labels: departmentData.map(department => department.department),
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontSize: '14px',
      markers: {
        width: 12,
        height: 12,
        radius: 12,
      },
    },
  
  };

  const chartSeries = departmentData.map(department => department.employeeCount);

  return (
    <div style={{ backgroundColor:"#F4F4F4"}} className=" shadow-lg rounded-lg p-4 text-center">
      <h2 className="text-2xl font-semibold mb-4">Employees Per Department</h2>
      <div className="flex justify-center">
        <ReactApexChart options={chartOptions} series={chartSeries} type="pie" height={350} />
      </div>
    </div>
  );
};

export default EmployeesPerDepartmentChart;
