import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import PresentEmployees from './components/PresentEmployes';
import AbsentEmployees from './components/AbsentEmployees';
import WFHEmployees from './components/WFHEmployees';
import LeaveEmployees from './components/LeaveEmployees';
import LateEmployees from './components/LateEmployees';
import AllEmployees from './components/AllEmployees';


function App() {
  const isAuthenticated = !!localStorage.getItem('token');

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/home" element={<Home />} />
        <Route path="/absent-employees-details" element={<AbsentEmployees />} />
        <Route path="/WFH-employees-details" element={<WFHEmployees />} />
        <Route path="/present-employees-details" element={<PresentEmployees />} />
        <Route path="/leave-employees-details" element={<LeaveEmployees />} />
        <Route path="/late-employees-details" element={<LateEmployees />} />
        <Route path="/all-employees-details" element={<AllEmployees />} />
        {/* Wildcard route to handle unmatched routes */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
