import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FaUser, FaArrowLeft } from "react-icons/fa";
import ReactPaginate from "react-paginate";

const AbsentEmployees = () => {
  const location = useLocation();
  const { absentEmployees } = location.state;

  // State for pagination
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const employeesPerPage = 25;

  // Function to handle page change
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // Filter out the employees with undefined status
  const validAbsentEmployees = absentEmployees.filter(
    (employee) => employee.status === "Absent"
  );

  // Extract unique department names
  const departments = [...new Set(validAbsentEmployees.map((employee) => employee.department))];

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Function to handle department filter change
  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
    setPageNumber(0); // Reset page number when changing department
  };

  // Filter employees based on selected department
  const filteredEmployees = selectedDepartment === "All"
    ? validAbsentEmployees
    : validAbsentEmployees.filter(employee => employee.department === selectedDepartment);

  // Calculate the index of the first and last employee to display on the current page
  const startIndex = pageNumber * employeesPerPage;
  const endIndex = startIndex + employeesPerPage;
  const displayedEmployees = filteredEmployees.slice(startIndex, endIndex);

  return (
    <div className="min-h-screen px-4 bg-white py-8">
      <div className="container mx-auto">
        <button
          onClick={() => window.history.back()}
          className="flex items-center rounded bg-blue-950 text-white py-2 px-2 mb-4"
        >
          <FaArrowLeft className="mr-2" />
          Go Back
        </button>

        <h2 className="text-3xl font-semibold mb-8 text-black">
          Absent Employees Details
        </h2>

  {/* Department filter */}
<div className="flex justify-end mb-6">
  <label htmlFor="departmentFilter" className="mr-2 mt-2 font-bold text-black">View Records by Department:</label>
  <select
    id="departmentFilter"
    className="border border-black rounded p-2 font-semibold text-black"
    value={selectedDepartment}
    onChange={handleDepartmentChange}
  >
    <option value="All">All Departments</option>
    {departments.map((department, index) => (
      <option key={index} value={department}>{department}</option>
    ))}
  </select>
</div>


        {/* Employee list */}
        <div className="flex justify-between items-center bg-blue-950 p-6 rounded-lg mb-8 overflow-x-auto">
          {/* Table headers */}
          <div className="w-1/5 text-center text-lg font-bold text-white">Image</div>
          <div className="w-1/5 text-center text-lg font-bold text-white">Name</div>
          <div className="w-1/5 text-center text-lg font-bold text-white">Department</div>
          <div className="w-1/5 text-center text-lg font-bold text-white">Date</div>
          <div className="w-1/5 text-center text-lg font-bold text-white">Status</div>
        </div>

        {/* Display filtered employees */}
        {displayedEmployees.map((employee) => (
          <div key={employee.id} className="flex shadow-lg rounded-lg mb-4 transition-transform transform hover:translate-y-1 overflow-x-auto">
            {/* Employee details */}
            <div className="w-1/5 p-2 flex justify-center items-center">
              <FaUser className="h-8 w-8 text-gray-800" />
            </div>
            <div className="w-1/5 p-2 flex justify-center items-center">
              <div className="text-center">
                <div className="font-semibold text-md text-gray-800">
                  {employee.employeeName}
                </div>
              </div>
            </div>
            <div className="w-1/5 p-2 flex justify-center items-center">
              <div className="text-center">
                <div className="text-gray-800 text-md">{employee.department}</div>
              </div>
            </div>
            <div className="w-1/5 p-2 flex justify-center items-center">
              <div className="text-center">
                <div className="text-gray-800 text-md">{formatDate(employee.date)}</div>
              </div>
            </div>
            <div className="w-1/5 p-2 flex justify-center items-center">
              <div className="text-center">
                <div className="text-md text-red-600 font-bold">
                  {employee.status.toUpperCase()}
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* Pagination */}
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={Math.ceil(filteredEmployees.length / employeesPerPage)}
          onPageChange={changePage}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </div>
    </div>
  );
};

export default AbsentEmployees;
