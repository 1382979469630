import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FaUser, FaArrowLeft } from "react-icons/fa";
import ReactPaginate from "react-paginate";

const LateEmployees = () => {
  const location = useLocation();
  const { lateEmployees } = location.state;

  const [selectedDepartment, setSelectedDepartment] = useState("All");

  // State for pagination
  const [pageNumber, setPageNumber] = useState(0);
  const employeesPerPage = 25; // Change this value to adjust the number of employees per page
  const pagesVisited = pageNumber * employeesPerPage;

  // Function to handle page change
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // Filter out the employees with undefined status
  const validlateEmployees = lateEmployees.filter(
    (employee) => employee.status === "Late"
  );

  const departments = [...new Set(validlateEmployees.map((employee) => employee.department))];

  // Filter employees based on selected department
  const filteredEmployees = selectedDepartment === "All"
    ? validlateEmployees
    : validlateEmployees.filter(employee => employee.department === selectedDepartment);

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleBack = () => {
    window.history.back();
  };

  // Function to handle department filter change
  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
    setPageNumber(0); // Reset page number when changing department
  };

  return (
    <div className="min-h-screen  px-4 py-8">
      <div className="container mx-auto">
        <button
          id="login"
          onClick={handleBack}
          className="flex items-center rounded text-white  py-2 px-2 mb-4"
        >
          <FaArrowLeft className="mr-2" />
          Go Back
        </button>

        <h2 className="text-3xl font-semibold mb-8 text-black">
          Late Employees Details
        </h2>

        {/* Department filter */}
        <div className="flex justify-end mb-6">
          <label htmlFor="departmentFilter" className="mr-2 mt-2 font-bold text-black">View Records by Department:</label>
          <select
            id="departmentFilter"
            className="border border-black rounded p-2 font-semibold text-black"
            value={selectedDepartment}
            onChange={handleDepartmentChange}
          >
            <option value="All">All Departments</option>
            {departments.map((department, index) => (
              <option key={index} value={department}>{department}</option>
            ))}
          </select>
        </div>

        <div className="flex justify-between bg-blue-950 items-center p-4 rounded-lg mb-8 overflow-x-auto">
          <div className="w-1/5 text-center text-xl font-bold text-white">Image</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">Name</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">Department</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">Start Time</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">End Time</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">Total Hours</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">Date</div>
          <div className="w-1/5 text-center text-xl font-bold text-white">Status</div>
        </div>

        {filteredEmployees
          .slice(pagesVisited, pagesVisited + employeesPerPage)
          .map((employee) => (
            <div key={employee.id} className="flex transition-transform transform hover:translate-y-1 shadow-lg rounded-full overflow-x-auto mb-4">
              <div className="w-1/5 p-2 flex justify-center items-center">
                <FaUser className="h-8 w-8 text-gray-800" />
              </div>
              <div className="w-1/5 p-2 flex justify-center items-center">
                <div className="text-center">
                  <div className="font-semibold text-lg text-gray-800">
                    {employee.employeeName}
                  </div>
                </div>
              </div>
              <div className="w-1/5 p-2 flex justify-center items-center">
                <div className="text-center">
                  <div className="text-gray-800 text-sm">{employee.department}</div>
                </div>
              </div>
              <div className="w-1/5 p-2 flex justify-center items-center">
                <div className="text-center">
                  <div className="text-gray-800 text-base">{employee.startTime}</div>
                </div>
              </div>
              <div className="w-1/5 p-2 flex justify-center items-center">
                <div className="text-center">
                  <div className="text-gray-800 text-base">{employee.endTime}</div>
                </div>
              </div>
              <div className="w-1/5 p-2 flex justify-center items-center">
                <div className="text-center">
                  <div className="text-gray-800 text-base">{employee.totalHours}</div>
                </div>
              </div>
              <div className="w-1/5 p-2 flex justify-center items-center">
                <div className="text-center">
                  <div className="text-gray-800 text-base">{formatDate(employee.date)}</div>
                </div>
              </div>
              <div className="w-1/5 p-2 flex justify-center items-center">
                <div className="text-center">
                  <div className="font-bold text-lg text-yellow-500">{employee.status.toUpperCase()}</div>
                </div>
              </div>
            </div>
          ))}

        {/* Pagination */}
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={Math.ceil(validlateEmployees.length / employeesPerPage)}
          onPageChange={changePage}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </div>
    </div>
  );
};

export default LateEmployees;
