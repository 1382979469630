import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaArrowLeft, FaSearch } from "react-icons/fa";
import ReactPaginate from "react-paginate";

const AllEmployees = () => {
  const location = useLocation();
  const data = location.state;
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const employeesPerPage = 25;

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleBack = () => {
    window.history.back();
  };

  const handleTabClick = (department) => {
    setSelectedDepartment(department === "All" ? null : department);
  };

  useEffect(() => {
    // Flatten the data structure into an array of employees
    const allEmployees = Object.values(data).flat();
    // Filter employees based on search query and selected department
    const filtered = allEmployees.filter(
      (employee) =>
        employee.employeeName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) &&
        (!selectedDepartment || employee.department === selectedDepartment)
    );
    setFilteredEmployees(filtered);
  }, [searchQuery, selectedDepartment, data]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * employeesPerPage;
  const pageCount = Math.ceil(filteredEmployees.length / employeesPerPage);
  const currentEmployees = filteredEmployees.slice(
    offset,
    offset + employeesPerPage
  );

  return (
    <div className="min-h-screen px-4 py-8">
      <div className="container mx-auto">
        <button
          id="login"
          onClick={handleBack}
          className="flex items-center rounded text-white py-2 px-2 mb-4 bg-blue-900 hover:bg-blue-600"
        >
          <FaArrowLeft className="mr-2" />
          Go Back
        </button>

        <h2 className="text-3xl font-semibold mb-8 text-gray-800">
          All Employees Details
        </h2>

        {/* Search input field */}
        <div className="relative mb-4">
          <input
            type="text"
            placeholder="Search by Name"
            className="w-full p-2 pl-10 rounded border border-gray-300 focus:outline-none focus:border-blue-900"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <FaSearch className="absolute top-2 left-3 text-gray-400" />
        </div>

        {/* Department tabs */}
        <div className="flex flex-wrap mb-4">
          {["All", ...Object.keys(data)].map((department) => (
            <button
              key={department}
              onClick={() => handleTabClick(department)}
              className={`mr-4 mb-2 px-4 py-2 rounded shadow-lg focus:outline-none ${selectedDepartment === department
                  ? "bg-blue-950 text-black"
                  : "text-gray-800 hover:bg-blue-500 hover:text-black"
                }`}
              style={{
                backgroundColor: selectedDepartment === department ? "#112C48" : "#F4F4F4",
                color: selectedDepartment === department ? "white" : "black"
              }}
            >
              {department}
            </button>
          ))}
        </div>

        {/* Employee table */}
        <div className="overflow-x-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <div id="login" className="p-4 text-white font-bold border border-gray-300 rounded-tl-lg">
              Name
            </div>
            <div id="login" className="p-4  text-white font-bold border border-gray-300 rounded-tl-lg">
              Department
            </div>
            <div id="login" className="p-4  text-white font-bold border border-gray-300 rounded-tl-lg">
              Date Of Joining
            </div>
            <div id="login" className="p-4  text-white font-bold border border-gray-300 rounded-tl-lg">
              Role
            </div>

            {currentEmployees.map((employee) => (
              <React.Fragment key={employee.id}>
                <div style={{ backgroundColor: '#F4F4F4' }} className="p-4 rounded-lg shadow-lg">
                  {employee.employeeName}
                </div>
                <div style={{ backgroundColor: '#F4F4F4' }} className="p-4 rounded-lg shadow-lg">
                  {employee.department}

                </div>
                <div style={{ backgroundColor: '#F4F4F4' }} className="p-4 rounded-lg shadow-lg">
                  {formatDate(employee.dateOfJoining)}

                </div>
                <div style={{ backgroundColor: '#F4F4F4' }} className="p-4 rounded-lg shadow-lg">
                  {employee.role}
                </div>

              </React.Fragment>
            ))}
          </div>
        </div>

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          // previousClassName={"px-4 py-2 ext-white rounded focus:outline-none bg-blue-900 t"}
          // nextClassName={"px-4 text-white py-2 rounded focus:outline-none bg-blue-900 "}
          />
        </div>
      </div>
    </div>
  );
};

export default AllEmployees;
