import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';

const Branch1Graph = () => {
  const [graphData, setGraphData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the "branch-performance" endpoint
        const response = await axios.get('https://superadmin.marsbpo.org/api/branch1/branch-performance');
        const branchPerformanceData = response.data;

        // Prepare data for chart
        const categories = branchPerformanceData.map(item => item.department);
        const seriesData = [
          {
            name: 'Attendance Percentage',
            data: branchPerformanceData.map(item => parseFloat(item.attendancePercentage)),
          },
        //   {
        //     name: 'Total Employees',
        //     data: branchPerformanceData.map(item => item.totalEmployees),
        //   },
          {
            name: 'Present Count',
            data: branchPerformanceData.map(item => item.presentCount),
          },
          {
            name: 'Absent Count',
            data: branchPerformanceData.map(item => item.absentCount),
          },
          {
            name: 'Leave Count',
            data: branchPerformanceData.map(item => item.leaveCount),
          },
          {
            name: 'Late Count',
            data: branchPerformanceData.map(item => item.lateCount),
          },
          {
            name: 'WFH Count',
            data: branchPerformanceData.map(item => item.WFHCount),
          },
        ];

        setGraphData({
          options: {
            chart: {
              type: 'bar',
              height: 300,
              stacked: true,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                barHeight: '80%',
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: categories,
            },
            legend: {
              position: 'top',
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  if (typeof val === 'number') {
                    return val.toFixed(2);
                  }
                  return val;
                }
              }
            }
          },
          series: seriesData,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="chart-container">
      {graphData.options && graphData.series && (
        <ReactApexChart
          options={graphData.options}
          series={graphData.series}
          type="bar"
          height={300}
        />
      )}
    </div>
  );
};

export default Branch1Graph;
